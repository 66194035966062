<template>
  <div class="profile-container grey lighten-3" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <main-nav v-if="$vuetify.breakpoint.mdAndUp"></main-nav>

    <mobile-nav v-if="$vuetify.breakpoint.smAndDown"></mobile-nav>


    <div class="fill-height" v-if="!$isEmpty(user) || user !== undefined">

      <v-card class="welcome gradientBG ma-4 white--text pa-4 d-flex align-center flex-wrap" v-if="showNewAccount">
        <div class="col-12 col-md-9">
          <h1>Setup your avatar below</h1>
          <p class="font-weight-medium">This will be the persona that show up on each Youtorial you create.</p>
        </div>
        <div class="col-12 col-md-3 img-container">
          <v-btn  color="white" @click="finalizeSetup">
            <template v-if="Object.hasOwnProperty.call(user, 'avatar')">
              Ok, Done!
            </template>
            <template v-else>
              Nah, just use the default
            </template>
          </v-btn>
        </div>
      </v-card>


      <template v-if="!showAvatar">
        <div class="d-flex align-stretch flex-xs-column flex-sm-column flex-md-row pa-2 flex-wrap">
          <div class="col-12 col-md-4 flex-auto d-flex flex-column">
            <v-card class="d-flex flex-column align-center">
              <v-card-title>Profile Info</v-card-title>
              <div class="d-flex align-center" v-if="!preferAvatar">
                <span>Image</span>
                <v-switch
                v-model="localUser.useAvatar"
                ></v-switch>
                <span>Avatar</span>
              </div>

              <v-card-text class="d-flex align-center flex-column" v-if="!$isEmpty(localUser)">
              <v-avatar size="100" :color="localUser.hasOwnProperty('avatar') ? localUser.bg : 'primary'">
                <v-img class="user-avatar" v-if="preferAvatar && localUser.hasOwnProperty('avatar')" :src="localUser.avatar"/>
                <v-img class="default-avatar" v-else-if="preferAvatar && !localUser.hasOwnProperty('avatar')" :src="avatar"/>
                <img v-else :src="photoURL"/>
              </v-avatar>
              <br>
              <v-btn v-if="preferAvatar" fab small color="grey lighten-4" class="editBtn" @click="showAvatar = true">
                <v-icon color="grey darken-2">settings</v-icon>
              </v-btn>

              <v-text-field v-model="localUser.displayName" label="Display Name" ></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-btn text>Cancel</v-btn>
                <v-btn color="primary" @click="saveAvatar(localUser)">Save</v-btn>
            </v-card-actions>


            </v-card>


          </div>
          <div class="col-12 col-md-4 flex-auto d-flex flex-column align-self-auto">
            <v-card class="fill-height">
              <v-card-title>My Youtorial Statistics</v-card-title>
              <v-card-text class="text-center">
                <v-icon size="120" color="secondary">signal_cellular_alt</v-icon>
                <br>
                <i>coming soon...</i>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </template>
      <avatar-builder v-else :user="user" @closeAvatar="closeAvatar" @saveAvatar="setAvatar"></avatar-builder>
    </div>

    <div class="fill-height d-flex align-center justify-center" v-else>
      <v-card class="max-width:300px">
        <v-card-title class="gradientBG white--text">Creating Profile</v-card-title>
        <v-card-text class="d-flex flex-column align-center">
          Hold tight a second...
          <div class="col-4 ">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </div>


    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      dark
    >
      {{ snackbar.message }}
      <v-btn
        text
        color="white"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>


  </div>
</template>

<script>
const mainNav = () => import("@/components/shared/nav.vue");
const mobileNav = () => import("@/components/shared/mobile-nav.vue");
const avatarBuilder = () => import("@/components/shared/avatar-builder.vue");

import { db } from '@/firebase';
import { mapGetters } from 'vuex'

export default {
  name: "profile",
  components:{
    mainNav, mobileNav,avatarBuilder,
  },
  computed:{
    ...mapGetters({
      user:'GET_USER',
      userId:'GET_USER_ID',
    }),
  },
  watch:{
    user: function(val){
      process.env.NODE_ENV === "development" ? console.log( 'user val', val ) : null;
      if(!this.$isEmpty(val)){
        let newval = JSON.parse(JSON.stringify(val));
        this.setUp(newval);
        clearInterval(this.profileChecker)
      }
    }
  },
  data(){
    return{
      isLoaded: false,
      showNewAccount: false,
      profileChecker: "",
      localUser:{},
      showAvatar:false,
      avatarType: false,
      preferAvatar: false,
      displayName: "",
      photoURL: "",
      snackbar:{
        show:false,
      },
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },
  methods:{
    setUp(val){
      this.displayName = Object.hasOwnProperty.call(val, 'displayName') ? val.displayName : val.name;
      process.env.NODE_ENV === "development" ? console.log( 'setup user val', typeof val, val ) : null;
      this.localUser = val;
      // process.env.NODE_ENV === "development" ? console.log( 'dev user', val.photoURL != null, val.photoURL ) : null;
      if(val.photoURL != null){
        this.photoURL = val.photoURL;
      }
      else if (Object.hasOwnProperty.call(val, 'avatar')) {
        this.avatar = val.avatar;
        this.avatarType = true;
        this.localUser.useAvatar = true;
        this.preferAvatar = true;
      }
      else{
        this.localUser.avatar = "https://avataaars.io/?accessoriesType=Blank&avatarStyle=Transparent&clotheColor=Pink&clotheType=GraphicShirt&eyeType=Squint&eyebrowType=Default&facialHairColor=Auburn&facialHairType=MoustacheFancy&graphicType=SkullOutline&hairColor=BrownDark&hatColor=Blue03&mouthType=Tongue&skinColor=Pale&topType=Hat"
        this.localUser.bg = "#f2f2f2";
        this.avatarType = true;
        this.localUser.useAvatar = true;
        this.preferAvatar = true;
      }

      if(!Object.hasOwnProperty.call(val,'displayName')){
          this.localUser.displayName = val.name;
      }

    },
    closeAvatar(){
      this.showAvatar = false;
    },
    setAvatar(data){
      this.localUser.avatar = data.avatar;
      this.localUser.bg = data.bg;
      this.showAvatar = false;
    },
    saveAvatar(data, final){
      this.$store.dispatch('updateUser', data).then((x) => {
        let vThis = this;
        vThis.snackbar ={
          'show': true,
          'message': 'Profile Saved',
          'color':'success',
          'action': 'close'
        }
        vThis.snackbarTimer = setTimeout(() => {
          vThis.snackbar.show = false;
          vThis.autoSaveStatus = 'done';
          if(final != undefined){
            vThis.$router.push({name:'build'})
          }
        },3000)
      }).catch((error)=>{
        let vThis = this;
        vThis.snackbar ={
          'show': true,
          'message': error,
          'color':'error',
          'action': 'close'
        }
      });
    },
    checkUserStatus(){
      let vThis = this;
      vThis.profileChecker = setInterval(() => {
        vThis.$store.dispatch('checkUserStatus').then((x)=>{
          process.env.NODE_ENV === "development" ? console.log( 'x',x ) : null;
          if(Object.hasOwnProperty.call(x, 'account')){
            clearInterval(vThis.profileChecker)
          }
        })
      },1000);

    },
    finalizeSetup(){
      process.env.NODE_ENV === "development" ? console.log( Object.hasOwnProperty.call(this.user, 'avatar') ) : null;
      if(!Object.hasOwnProperty.call(this.user, 'avatar')){
        this.saveAvatar(this.localUser,true)
      }
      else{

        this.$router.push({name:'build'})
      }
    }
  },
  mounted(){
    if(this.$vuetify.breakpoint.mdAndUp){
      this.$store.dispatch('toggleDrawer', true);
    }
    if(Object.hasOwnProperty.call(this.$route.query, 'newAccount')){
      this.showNewAccount = true;
      process.env.NODE_ENV === "development" ? console.log( 'profile user', this.$isEmpty(this.$store.state.user) ) : null;
      process.env.NODE_ENV === "development" ? console.log( 'profile user', this.user ) : null;
      if(this.user == undefined || this.$isEmpty(this.user)){
        process.env.NODE_ENV === "development" ? console.log( 'no user found' ) : null;
        this.checkUserStatus();
      }
    }
    else{
      if(!this.$isEmpty(this.user)){
        this.setUp(this.user)
      }
    }


  }
};
</script>


<style lang="scss">
.profile-container{
  position: relative;
  height: 100vh;
  padding-top: 70px;
  padding-left: 280px;
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
  }
}
</style>
